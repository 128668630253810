import { extendTheme } from '@chakra-ui/react';

// Define your custom theme based on Quilta's updated brand guidelines
const customTheme = extendTheme({
  colors: {
    primary: {
      500: '#000000', // Black for all primary text (leave this for other components if needed)
    },
    attentionPalette: {
      sunriseYellow: '#ffa502', // Attention-grabbing color for key CTAs
      lavaRed: '#f96048', // High-contrast red for important interactions
      forestGreen: '#30a234', // Positive action and success state color
      blossomPink: '#e460a3', // Soft but impactful for secondary highlights
    },
    tealBlue: {
      500: '#25787b', // For buttons, container backgrounds, but not full page backgrounds
    },
    background: {
      dark: '#202528', // Dark background for dark mode
      light: '#FFFFFF', // White background for light mode
      gradient: 'linear(to-r, #25787b, #212529)', // Full background gradient option
    },
    text: {
      light: '#333333', // Dark Charcoal for light mode
      dark: '#f5f5f5', // Smoke White for dark mode
    },
  },
  fonts: {
    heading: `'Poppins', sans-serif`, // Poppins for headings
    body: `'Inter', sans-serif`, // Inter for body text
  },
  radii: {
    sm: '4px',
    md: '8px',
    lg: '10px', // Maximum rounding for corners (as per guidelines)
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '8px', // Rounded corners for buttons
        fontWeight: 'bold', // Bold text for buttons
      },
      variants: {
        primary: {
          bg: 'attentionPalette.sunriseYellow',
          color: 'white',
          _hover: {
            bg: 'attentionPalette.lavaRed', // Hover effect for primary buttons
          },
        },
        secondary: {
          bg: 'attentionPalette.forestGreen',
          color: 'white',
          _hover: {
            bg: 'attentionPalette.blossomPink', // Hover effect for secondary buttons
          },
        },
        tealBlue: {
          bg: 'tealBlue.500', // New button variant using the teal-blue color
          color: 'white',
          _hover: {
            bg: 'attentionPalette.lavaRed',
          },
        },
      },
    },
    Table: {
      baseStyle: {
        tableLayout: 'auto',
        borderCollapse: 'collapse',
      },
      sizes: {
        md: {
          th: {
            fontWeight: 'bold',
            color: 'primary.500',
            borderBottom: '2px solid',
            borderColor: 'primary.500',
          },
          td: {
            color: 'primary.500',
            padding: '10px',
            borderBottom: '1px solid',
            borderColor: 'primary.500',
          },
        },
      },
    },
  },
  textStyles: {
    h1: {
      fontSize: ['36px', '48px'],
      fontWeight: 'bold',
      lineHeight: '110%',
      letterSpacing: '-0.01em',
      color: 'primary.500', // Black for headings
    },
    h2: {
      fontSize: ['30px', '36px'],
      fontWeight: 'bold',
      lineHeight: '110%',
      color: 'primary.500', // Black for sub-headings
    },
    h3: {
      fontSize: ['24px', '30px'],
      fontWeight: 'semi-bold',
      lineHeight: '110%',
      color: 'primary.500', // Black for smaller headings
    },
    body: {
      fontSize: ['16px', '18px'],
      fontWeight: 'normal',
      color: 'text.light', // Dark Charcoal for body text in light mode
    },
    lightText: {
      fontSize: ['14px', '16px'],
      fontWeight: 'light',
      color: 'primary.500', // Light black for additional text
    },
  },
  styles: {
    global: {
      body: {
        bg: 'background.light', // Default light background
        color: 'text.light', // Dark Charcoal for text in light mode
        transition: 'background-color 0.3s ease',
        _dark: {
          bg: 'background.dark', // Dark mode background
          color: 'text.dark', // Smoke White for text in dark mode
        },
      },
      a: {
        color: 'attentionPalette.sunriseYellow',
        _hover: {
          color: 'attentionPalette.lavaRed', // Hover effect for links
        },
      },
    },
  },
});

export default customTheme;
