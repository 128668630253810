import { gql } from '@apollo/client';

// Mutation to create a new AppContentObject with componentId
export const CREATE_APP_CONTENT_OBJECT = gql`
  mutation CreateAppContentObject(
    $pageId: ID!,
    $componentId: ID!,
    $x: Int!,
    $y: Int!,
    $width: Int!,
    $height: Int!,
    $linkedContent: [AppContentValueInput!]  # Ensure non-nullable input
  ) {
    createAppContentObject(
      pageId: $pageId,
      componentId: $componentId,
      x: $x,
      y: $y,
      width: $width,
      height: $height,
      linkedContent: $linkedContent  # camelCase for linkedContent
    ) {
      id
      component {
        id
        name
        component_type  # Ensure correct camelCase
      }
      x
      y
      width
      height
      linkedContent {  # Query in camelCase
        fieldName  # Correct camelCase
        value
        fieldType
      }
    }
  }
`;

// Mutation to update the properties of an existing AppContentObject
export const UPDATE_APP_CONTENT_OBJECT = gql`
  mutation UpdateAppContentObject(
    $id: ID!,
    $x: Int!,
    $y: Int!,
    $width: Int!,
    $height: Int!,
    $componentId: ID
  ) {
    updateAppContentObject(
      id: $id,
      x: $x,
      y: $y,
      width: $width,
      height: $height,
      componentId: $componentId
    ) {
      id
      component {
        id
        name
        component_type
      }
      x
      y
      width
      height
    }
  }
`;


export const DELETE_APP_CONTENT_OBJECT = gql`
  mutation DeleteAppContentObject($id: ID!) {
    deleteAppContentObject(id: $id)
  }
`;

export const SUBMIT_PROMPT = gql`
  mutation SubmitPrompt($promptText: String!, $pageId: ID!) {
    submitPrompt(promptText: $promptText, pageId: $pageId) {
      success
      message
      promptId
    }
  }
`;



export const CREATE_APP = gql`
  mutation CreateApp(
    $appName: String!,
    $description: String!,
    $useCase: String!,
    $userType: String!,
    $primaryColor: String!,
    $secondaryColor: String!
  ) {
    createApp(
      input: {
        appName: $appName,
        description: $description,
        useCase: $useCase,
        userType: $userType,
        primaryColor: $primaryColor,
        secondaryColor: $secondaryColor
      }
    ) {
      id
      appName
      description
    }
  }
`;