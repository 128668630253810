import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // To get appId and pageId from URL
import Header from '../../components/quiltaComponents/Header';
import Footer from '../../components/quiltaComponents/Footer';
import GridEditor from '../../components/quiltaComponents/GridEditor';
import Sidebar from '../../components/quiltaComponents/Sidebar';
import { useQuery, useMutation, gql } from '@apollo/client';
import { GET_APP_PAGE_CONTENT_OBJECTS } from '../../utils/graphql/queries';
import { SUBMIT_PROMPT } from '../../utils/graphql/mutations';  // Import the mutation
import styled from 'styled-components';

// Define a query to fetch components
const GET_COMPONENTS = gql`
  query GetComponents {
    components {
      id
      name
      props
    }
  }
`;

const PromptInputContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 600px;
  padding: 10px;
  background-color: white;
  border-left: 1px solid #ddd;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const EditorPage = () => {
  const { appId, pageId } = useParams();  // Get the appId and pageId from the URL
  const [gridItems, setGridItems] = useState([]);
  const [promptText, setPromptText] = useState('');  // Add state for the input value
  const [submitPrompt] = useMutation(SUBMIT_PROMPT);  // Use the mutation

  // Fetch content objects based on appId and pageId
  const { data, loading, error } = useQuery(GET_APP_PAGE_CONTENT_OBJECTS, {
    variables: { appId, pageId },
  });

  // Fetch available components from the backend
  const { data: componentData, loading: componentsLoading, error: componentsError } = useQuery(GET_COMPONENTS);

  useEffect(() => {
    if (loading || !data) return;

    if (data?.appPage?.contentObjects) {
      const items = data.appPage.contentObjects.map((object) => ({
        id: object.id,
        componentId: object.component.id,  // Correctly extract componentId
        componentType: object.component.component_type,  // Correctly extract componentType
        x: object.x,
        y: object.y,
        width: object.width,
        height: object.height,
        linkedContent: object.linkedContent || []  // Include linkedContent to pass it through
      }));
      setGridItems(items);
    }
  }, [data, error, loading]);

  const handleDragStart = (e, component) => {
    e.dataTransfer.setData('componentId', component.id);
    e.dataTransfer.setData('componentName', component.name);
  };

  const handlePromptSubmit = async () => {
    try {
      const { data } = await submitPrompt({
        variables: { promptText, pageId },
      });
      console.log('Prompt submitted successfully:', data.submitPrompt.message);
      setPromptText('');  // Clear the input box
    } catch (error) {
      console.error('Error submitting prompt:', error);
    }
  };

  if (loading || componentsLoading) {
    return <p>Loading...</p>;
  }

  if (error || componentsError) {
    return <p>Error loading data: {error?.message || componentsError?.message}</p>;
  }

  const components = componentData.components;

  return (
    <>
      <Header />
      <PromptInputContainer>
        <label htmlFor="prompt-input">Submit Prompt:</label>
        <input
          id="prompt-input"
          type="text"
          value={promptText}
          onChange={(e) => setPromptText(e.target.value)}
          placeholder="Enter your prompt"
        />
        <button onClick={handlePromptSubmit}>Submit</button>
      </PromptInputContainer>
      <Sidebar
        components={components}
        onDragStart={handleDragStart}
      />
      <GridEditor orgId="1" appId={appId} pageId={pageId} initialItems={gridItems} />
      <Footer />
    </>
  );
};

export default EditorPage;
