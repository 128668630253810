import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { ChakraProvider, Box, Text, useMediaQuery, Flex, Spinner } from '@chakra-ui/react';
import customTheme from './theme/theme'; 
import EditorPage from './pages/EditorPage/EditorPage';
import BrandBook from './pages/BrandBook/Brandbook';
import Home from './pages/MainSite/Home';
import SignupPage from './pages/EditorPage/SignupPage';
import LoginPage from './pages/LoginPage/LoginPage';
import UserSpace from './pages/UserSpace/UserSpace';
import CreateAppPage from './pages/CreateAppPage/CreateAppPage';

import Hotjar from '@hotjar/browser';

const siteId = 5178085;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const IS_DEVELOPMENT = process.env.REACT_APP_ENV === 'development';

const getCookie = (name) => {
  const cookies = document.cookie.split('; ');
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      console.log(`Retrieved cookie: ${name} = ${cookieValue}`);
      return cookieValue;
    }
  }
  console.log(`Cookie not found: ${name}`);
  return null;
};

const MobileWarningMessage = () => {
  const [isMobileView] = useMediaQuery("(max-width: 0px)");

  return (
    <>
      {isMobileView && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(32, 37, 40, 0.9)"
          color="white"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          zIndex="9999"
          p={4}
        >
          <Box>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              Apologies, we do not support mobile at this time.
            </Text>
            <Text fontSize="lg">
              Please visit{' '}
              <a href="https://quilta.io" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                quilta.io
              </a>{' '}
              via a desktop computer to create your app.
            </Text>
          </Box>
        </Flex>
      )}
    </>
  );
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sessionTokenAssociated, setSessionTokenAssociated] = useState(false);
  const [hasCreatedApp, setHasCreatedApp] = useState(false);
  const [hasRedirected, setHasRedirected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleLoad = () => setIsLoading(false);
    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    if (IS_DEVELOPMENT) {
      const sessionToken = getCookie('sessionToken');
      if (!sessionToken) {
        createSession();
      } else {
        console.log('Session token exists:', sessionToken);
        handleSessionChecks();
      }
    } else {
      const csrfToken = getCookie('csrftoken');
      if (!csrfToken) {
        fetchCsrfToken().then(() => {
          const sessionToken = getCookie('sessionToken');
          if (!sessionToken) {
            createSession();
          } else {
            console.log('Session token exists:', sessionToken);
            handleSessionChecks();
          }
        });
      } else {
        console.log('CSRF token already exists:', csrfToken);
        const sessionToken = getCookie('sessionToken');
        if (!sessionToken) {
          createSession();
        } else {
          console.log('Session token exists:', sessionToken);
          handleSessionChecks();
        }
      }
    }

    console.log('App component mounted');
    return () => {
      console.log('App component unmounted');
    };
  }, []);

  const handleSessionChecks = async () => {
    const loggedIn = false;
    setIsLoggedIn(loggedIn);

    if (loggedIn) {
      navigate('/user-space');
    } else {
      const tokenAssociated = false;
      setSessionTokenAssociated(tokenAssociated);

      if (tokenAssociated) {
        if (!hasRedirected) {
          navigate('/login');
          setHasRedirected(true);
        }
      } else {
        const appCreated = false;
        setHasCreatedApp(appCreated);

        if (appCreated) {
          navigate('/app');
        } else {
          navigate('/create-app');
        }
      }
    }
  };

  const fetchCsrfToken = async () => {
    try {
      const response = await fetch('http://127.0.0.1:8000/api/get-csrf-token', {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        console.log('CSRF token fetched and cookie set');
      } else {
        console.error('Failed to fetch CSRF token');
      }
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };

  const createSession = async () => {
    try {
      let headers = {};

      if (!IS_DEVELOPMENT) {
        const csrfToken = getCookie('csrftoken');
        if (!csrfToken) {
          console.error('CSRF token not found. Cannot proceed with session creation.');
          return;
        }
        headers['X-CSRFToken'] = csrfToken;
      }

      const response = await fetch('http://127.0.0.1:8000/api/create-session', {
        method: 'POST',
        credentials: 'include',
        headers: headers,
      });
      if (response.ok) {
        console.log('Session created');
      } else {
        console.error('Failed to create session');
      }
    } catch (error) {
      console.error('Error creating session:', error);
    }
  };

  return (
    <ChakraProvider theme={customTheme}>
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          <MobileWarningMessage />
          <Routes>
            <Route path="/brandbook" element={<BrandBook />} />
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignupPage />} />
          </Routes>
        </>
      )}
    </ChakraProvider>
  );
};

export default App;
