import { Box, HStack, Icon, Link } from '@chakra-ui/react';
import { FaXTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa6';
import { MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <Box as="footer" w="100%" color="white">
      <HStack justify="center" spacing={6}>
        <Link href="mailto:hello@quilta.io" isExternal>
          <Icon as={MdEmail} boxSize={6} color="white" cursor="pointer" />
        </Link>
        <Link href="https://x.com/Quilta_ltd" isExternal>
          <Icon as={FaXTwitter} boxSize={6} color="white" cursor="pointer" />
        </Link>
        <Link href="https://www.instagram.com/quilta_ltd?igsh=MzZlbzFsajRzcDJ5" isExternal>
          <Icon as={FaInstagram} boxSize={6} color="white" cursor="pointer" />
        </Link>
        <Link href="https://www.linkedin.com/company/quilta/" isExternal>
          <Icon as={FaLinkedin} boxSize={6} color="white" cursor="pointer" />
        </Link>
      </HStack>
    </Box>
  );
};

export default Footer;
