import React from 'react';
import styled from 'styled-components';

// Define styled components
const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #282c34;
  color: white;
`;

const NavbarBrand = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const NavbarLinks = styled.ul`
  list-style: none;
  display: flex;
  gap: 1rem;
`;

const NavbarLink = styled.li`
  cursor: pointer;
  &:hover {
    color: #61dafb;
  }
`;

// Navbar component
const Navbar = () => {
  return (
    <NavbarContainer>
      <NavbarBrand>Quilta</NavbarBrand>
      <NavbarLinks>
        <NavbarLink>Home</NavbarLink>
        <NavbarLink>About</NavbarLink>
        <NavbarLink>Contact</NavbarLink>
      </NavbarLinks>
    </NavbarContainer>
  );
};

export default Navbar;