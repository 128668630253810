import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  Card,
  Heading,
  VStack,
  Text,
  Input,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Stack,
  CardBody
} from '@chakra-ui/react';
import Confetti from 'react-confetti';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const SignupPage = () => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiPieces, setConfettiPieces] = useState(300);
  const [confettiOpacity, setConfettiOpacity] = useState(1);
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const overlayBg = "rgba(0, 0, 0, 0.6)";
  const cardBg = useColorModeValue('white', 'gray.800');

  const PlaceholderText = styled(Box)`
    background-color: #e0e0e0;
    height: 16px;
    width: ${(props) => props.width || '100%'};
    border-radius: 8px;
    margin-bottom: ${(props) => props.mb || '8px'};
  `;

  const PlaceholderButton = styled(Box)`
    background-color: #bdbdbd;
    height: 36px;
    width: ${(props) => props.width || '100%'};
    border-radius: 24px;
    margin-bottom: 12px;
  `;

  const handleResize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleSignUpClick = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('EMAIL', email);
    formData.append('FNAME', firstName);
    formData.append('LNAME', lastName);
    formData.append('u', 'ba18d3f1dcdcca2ae68e26cdf');
    formData.append('id', '1f0e32c45e');

    try {
      await fetch("https://quilta.us8.list-manage.com/subscribe/post", {
        method: "POST",
        body: formData,
        mode: 'no-cors'
      });

      setShowSuccessMessage(true);
      setShowConfetti(true);
      setConfettiPieces(300);
      setConfettiOpacity(1);

      setTimeout(() => {
        const fadeInterval = setInterval(() => {
          setConfettiPieces((prev) => Math.max(prev - 30, 0));
          setConfettiOpacity((prev) => Math.max(prev - 0.1, 0));
          if (confettiPieces <= 0 && confettiOpacity <= 0) {
            clearInterval(fadeInterval);
            setShowConfetti(false);
          }
        }, 100);
      }, 3000);
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  const navigate = useNavigate();

  return (
    <Box position="relative">
      <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bg={overlayBg}
        zIndex="1000"
        pointerEvents="none"
      />

      {showConfetti && (
        <Confetti
          width={windowSize.width}
          height={windowSize.height}
          numberOfPieces={confettiPieces}
          recycle={false}
          tweenDuration={4000}
          initialVelocityX={{ min: -20, max: 20 }}
          initialVelocityY={{ min: 10, max: 20 }}
          friction={0.95}
          gravity={0.3}
          opacity={confettiOpacity}
          colors={['#25787b', '#81e6d9', '#3d0151', '#4FD1C5', '#212529']}
          style={{ zIndex: 2000, position: 'fixed', top: 0, left: 0 }}
        />
      )}

      <Flex
        gridColumn="span 12"
        gridRow="span 1"
        bgColor="gray.800"
        align="center"
        justify="space-between"
        p={4}
        color="white"
        
      >
        <PlaceholderText width="10%" />
        <Stack direction="row" spacing={4}>
          <Button colorScheme="teal" variant="outline">
            <PlaceholderText width="50px" />
          </Button>
          <Button colorScheme="teal">
            <PlaceholderText width="50px" />
          </Button>
        </Stack>
      </Flex>

      <Flex
        gridColumn="span 12"
        gridRow="span 2"
        bgColor="gray.100"
        align="center"
        justify="center"
        direction="column"
        p={8}
        borderRadius="md"
        m={4}
      >
        <PlaceholderText width="80%" mb="12px" />
        <PlaceholderText width="60%" mb="16px" />
        <PlaceholderButton width="30%" />
      </Flex>

      

      {/* Feature 1 */}
      <Flex m={4} gridColumn="span 4" gridRow="span 2" bgColor="gray.200" p={6} borderRadius="md" direction="column" justify="center" align="center">
        <Card w="full" p={4} borderRadius="md" bgColor="white" boxShadow="sm">
          <Heading size="md" mb={3}>
            <PlaceholderText width="50%" />
          </Heading>
          <CardBody>
            <Stack spacing={3}>
              <PlaceholderText width="90%" />
              <PlaceholderText width="80%" />
              <PlaceholderButton width="50%" />
            </Stack>
          </CardBody>
        </Card>
      </Flex>

      {/* Feature 2 */}
      <Flex m={4} gridColumn="span 4" gridRow="span 2" bgColor="gray.200" p={6} borderRadius="md" direction="column" justify="center" align="center">
        <Card w="full" p={4} borderRadius="md" bgColor="white" boxShadow="sm">
          <Heading size="md" mb={3}>
            <PlaceholderText width="50%" />
          </Heading>
          <CardBody>
            <Stack spacing={3}>
              <PlaceholderText width="90%" />
              <PlaceholderText width="80%" />
              <PlaceholderButton width="50%" />
            </Stack>
          </CardBody>
        </Card>
      </Flex>

      {/* Feature 3 */}
      <Flex m={4} gridColumn="span 4" gridRow="span 2" bgColor="gray.200" p={6} borderRadius="md" direction="column" justify="center" align="center">
        <Card w="full" p={4} borderRadius="md" bgColor="white" boxShadow="sm">
          <Heading size="md" mb={3}>
            <PlaceholderText width="50%" />
          </Heading>
          <CardBody>
            <Stack spacing={3}>
              <PlaceholderText width="90%" />
              <PlaceholderText width="80%" />
              <PlaceholderButton width="50%" />
            </Stack>
          </CardBody>
        </Card>
      </Flex>

      {/* Feature 4 */}
      <Flex m={4} gridColumn="span 4" gridRow="span 2" bgColor="gray.200" p={6} borderRadius="md" direction="column" justify="center" align="center">
        <Card w="full" p={4} borderRadius="md" bgColor="white" boxShadow="sm">
          <Heading size="md" mb={3}>
            <PlaceholderText width="50%" />
          </Heading>
          <CardBody>
            <Stack spacing={3}>
              <PlaceholderText width="90%" />
              <PlaceholderText width="80%" />
              <PlaceholderButton width="50%" />
            </Stack>
          </CardBody>
        </Card>
      </Flex>

      {/* Sign Up Form Overlay */}
      {!showSuccessMessage && (
        <Flex
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          align="center"
          justify="center"
          zIndex="1500"
        >
          <Card
            w={{ base: '90%', md: '50%', lg: '40%' }}
            p={8}
            borderRadius="lg"
            bg={cardBg}
            boxShadow="2xl"
            textAlign="center"
          >
            <Heading as="h2" size="lg" mb={4} color="#81e6d9">
              Sign Up to Quilta
            </Heading>
            <Text fontSize="md" mb={6} color="gray.500">
              Unlock early access to Quilta’s powerful AI tools—sign up today!
            </Text>
            <Box as="form" onSubmit={handleSignUpClick} noValidate>
              <VStack spacing={4}>
                <Input placeholder="First Name" size="lg" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                <Input placeholder="Last Name" size="lg" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                <Input placeholder="Email Address" size="lg" value={email} onChange={(e) => setEmail(e.target.value)} />
                <Button
                  type="submit"
                  size="lg"
                  bg="#ffa502"
                  color="black"
                  _hover={{ bg: 'yellow.300' }}
                >
                  Sign Up
                </Button>
              </VStack>
            </Box>
          </Card>
        </Flex>
      )}

      <Modal isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} isCentered>
        <ModalOverlay />
        <ModalContent bg={cardBg}>
          <ModalHeader textAlign="center">Thanks for Your Interest!</ModalHeader>
          <ModalBody pb={6} textAlign="center">
            <Text fontSize="lg" mb={4}>
              Our beta is just around the corner. You’ll be among the first to get an invite!
            </Text>
            <Button
              size="lg"
              bg="#ffa502"
              color="black"
              _hover={{ bg: 'yellow.300' }}
              onClick={() => navigate('/home')}
              mt={4}
            >
              Got it, thanks!
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SignupPage;
