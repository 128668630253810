import React from 'react';
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  Image,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorMode,
  Switch,
  HStack,
  Link,
  List,
  ListItem,
  Tooltip,
  useClipboard,
  VStack,
  Button,
  Wrap, WrapItem, Icon
} from '@chakra-ui/react';
import { AtSignIcon, CalendarIcon, PhoneIcon } from '@chakra-ui/icons'; // Import Chakra UI icons
import { MdSettings, MdReceipt, MdGroupWork, MdPieChart, MdPhoneIphone, MdCode } from 'react-icons/md';

// ColorSwatch Component for color swatches and descriptions
const ColorSwatch = ({ color, hex, description }) => {
  const { onCopy } = useClipboard(hex);

  return (
    <Box display="flex" alignItems="center" mb={6}>
      {/* Color Swatch Column */}
      <VStack spacing={4} align="center">
        <Tooltip label="Click to copy" fontSize="md">
          <Box
            bg={color}
            w="150px"
            h="150px"
            borderRadius="md"
            cursor="pointer"
            onClick={onCopy}
            position="relative"
          >
            <Text
              position="absolute"
              top="8px"
              left="50%"
              transform="translateX(-50%)"
              fontWeight="bold"
              color="white"
              fontSize="sm"
            >
              {hex}
            </Text>
          </Box>
        </Tooltip>
      </VStack>

      {/* Description Column */}
      <Box ml={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>
          {description.title}
        </Text>
        <Text fontSize="md">{description.body}</Text>
      </Box>
    </Box>
  );
};

const BrandBookPage = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const colors = [
    {
      color: '#25787b',
      hex: '#25787b',
      description: {
        title: 'Teal Blue',
        body: 'Our signature color, representing trust, reliability, and the forward-thinking mindset that Quilta embodies.',
      },
    },
    {
      color: '#ffa502',
      hex: '#ffa502',
      description: {
        title: 'Sunrise Yellow',
        body: 'Represents optimism, innovation, and warmth, used for key highlights and CTAs.',
      },
    },
    {
      color: '#f96048',
      hex: '#f96048',
      description: {
        title: 'Lava Red',
        body: 'Evokes urgency, passion, and bold action, ideal for high-impact moments.',
      },
    },
    {
      color: '#30a234',
      hex: '#30a234',
      description: {
        title: 'Forest Green',
        body: 'Symbolizes growth, success, and balance, perfect for positive outcomes and success states.',
      },
    },
    {
      color: '#e460a3',
      hex: '#e460a3',
      description: {
        title: 'Blossom Pink',
        body: 'Adds a creative, uplifting touch, making it perfect for secondary highlights and softer calls to action.',
      },
    },
  ];

  return (
    <Box display="flex" flexDirection="row">
      {/* Sidebar for Anchor Links */}
      <Box
        as="nav"
        position="fixed"
        top="0"
        left="0"
        height="100vh"
        width="240px"
        bg={colorMode === 'light' ? 'gray.100' : 'gray.700'}
        p={8}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Heading as="h3" size="md" mb={4}>
            Navigation
          </Heading>
          <List spacing={4}>
            <ListItem>
              <Link href="#welcome">Welcome</Link>
            </ListItem>
            <ListItem>
              <Link href="#mission">Mission</Link>
            </ListItem>
            <ListItem>
              <Link href="#logo">Logo & Visual Identity</Link>
            </ListItem>
            <ListItem>
              <Link href="#typography">Typography</Link>
            </ListItem>
            <ListItem>
              <Link href="#colors">Color Palette</Link>
            </ListItem>
            <ListItem>
              <Link href="#gradients">Gradient Backgrounds</Link>
            </ListItem>
            <ListItem>
              <Link href="#buttons">Buttons & Links</Link>
            </ListItem>
            <ListItem>
              <Link href="#tables">Tables & Components</Link>
            </ListItem>
          </List>
        </Box>
        
        {/* Light/Dark Mode Toggle */}
        <Box alignSelf="flex-end" mt={8}>
          <HStack>
            <Text>{colorMode === 'light' ? 'Light Mode' : 'Dark Mode'}</Text>
            <Switch isChecked={colorMode === 'dark'} onChange={toggleColorMode} />
          </HStack>
        </Box>
      </Box>

      {/* Main Content */}
      <VStack spacing={6} align="left" p={8}  mx="auto" ml="260px" position="relative">
        {/* Full-page Hero with Logo */}
        <Box
          id="welcome"
          mb={8}
          w="100%"
          h="500px"
          bgGradient={colorMode === 'light' ? 'linear(to-r, #25787b, #212529)' : 'linear(to-r, #25787b, #3d0151)'}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            src="/logo/quilta-logo-dark.png"
            alt="Quilta Logo"
            maxWidth="600px"
          />
        </Box>


        {/* Section 1: Welcome */}
        <Box mb={8}>
          <Heading as="h1" size="2xl" mb={4}>
            Welcome to Quilta
          </Heading>
          <Text fontSize="lg" mb={4}>
            At Quilta, we empower people with great ideas to turn them into reality, regardless of their technical
            background. We believe that anyone should be able to build custom applications that meet their unique needs,
            without needing to write a single line of code.
          </Text>
          <Text fontSize="lg">Quilta combines the power of no-code and AI, enabling you to create scalable, high-quality apps that help your business grow.</Text>
        </Box>

                {/* Section 2: Mission */}
                <Box id="mission" mb={8}>
          <Heading as="h2" size="xl" mb={4}>
            Our Mission
          </Heading>
          <Text fontSize="lg" mb={4}>
            Our mission is to democratise app creation by providing a platform that is powerful, flexible, and accessible
            to all.
          </Text>
          <Text fontSize="lg">
            We aim to eliminate barriers to technology, enabling you to build, customise, and scale web applications
            without needing development expertise.
          </Text>
        </Box>

        {/* Updated Image Sections with Reduced Line Spacing and Top-Aligned Text */}
        <Box
          position="relative"
          w="100%"
          h="800px"
          mb={6}
        >
          <Image
            src="/img/quilta-users-happy-outdoor-large.jpg"
            alt="Happy users outdoors"
            objectFit="cover"
            w="100%"
            h="100%"
            filter="brightness(0.7)"
          />
          <Text
            position="absolute"
            top="10%" /* Top-aligned text */
            left="10%" /* Left-aligned text */
            fontSize="6xl" /* Slightly larger font size */
            fontWeight="extrabold"
            color="white"
            zIndex="2"
            lineHeight="shorter" /* Reduced line height for less spacing between lines */
            maxW="60%"
          >
            Building innovation anywhere, empowering every step of the way.
          </Text>
        </Box>

        <Box
          position="relative"
          w="100%"
          h="800px"
          mb={6}

        >
          <Image
            src="img/quilta-users-happy-loung.jpg"
            alt="Happy users working in kitchen"
            objectFit="cover"
            w="100%"
            h="100%"
            filter="brightness(0.7)"
          />
          <Text
            position="absolute"
            top="10%" /* Top-aligned text */
            right="10%" /* Right-aligned text */
            fontSize="6xl" /* Slightly larger font size */
            fontWeight="extrabold"
            color="white"
            zIndex="2"
            textAlign="right"
            lineHeight="shorter" /* Reduced line height */
            maxW="60%"
          >
            Creativity in action, no code required.
          </Text>
        </Box>

        {/* Section: Image Text Guidelines */}
<Box id="guidelines" mb={8} p={6} borderWidth="1px" borderRadius="md" bg={colorMode === 'light' ? 'gray.50' : 'gray.700'} shadow="md">
  <Heading as="h3" size="lg" mb={4}>
    Image and Text Overlay Guidelines
  </Heading>
  <Text fontSize="lg" mb={4}>
    For images, always use warm, colorful visuals. Never use black-and-white images. Text over images should always be white, bold, and heavy (font-weight: 700 or 800). Use left or right alignment for the text, with sizes starting from 6xl (around 60px).
  </Text>
  <Text fontSize="lg" mb={4}>
    Apply a slight dimming filter (brightness of 0.7) to the image to ensure readability, and keep text width to a maximum of 60% of the image width for clarity and focus.
  </Text>
</Box>


{/* Section 4: Typography */}
<Box id="typography" mb={8}>
  <Heading as="h2" size="xl" mb={4}>
    Typography
  </Heading>
  <Text mb={4}>
    Our typography reflects clarity, professionalism, and approachability. We use sans-serif fonts to ensure readability across all devices and applications, while providing flexibility in weights for emphasis.
  </Text>
  
  <Text fontSize="2xl" fontFamily="heading" mb={2} fontWeight="700">
    Heading Font Example - Poppins
  </Text>
  <Text fontSize="xl" fontFamily="heading" mb={2} fontWeight="600">
    Subheading Font Example - Poppins
  </Text>
  <Text fontSize="md" fontFamily="body" mb={2} fontWeight="400">
    Body Text Example - Inter
  </Text>
  <Text fontSize="md" fontFamily="body" mb={2} fontWeight="300">
    Light Text Example - Inter
  </Text>
  <Text mb={4}>
    Heading fonts are used for clear hierarchy and focus. Body fonts maintain readability and a clean aesthetic, promoting seamless user experiences across digital platforms.
  </Text>
</Box>

{/* Section 13: Tone of Voice */}
<Box id="tone-of-voice" mb={8}>
  <Heading as="h2" size="xl" mb={4}>
    Tone of Voice
  </Heading>
  <Text mb={4}>
    Quilta’s tone of voice is conversational, approachable, and empowering. We believe in making technology accessible to everyone, which means our language is inclusive and easy to understand. 
  </Text>
  <Text mb={4}>
    Our tone conveys trust and innovation. We aim to sound knowledgeable without being overly technical, guiding our audience with confidence and clarity. Here’s how we communicate:
  </Text>

  <Heading as="h3" size="lg" mb={2}>1. Friendly & Accessible</Heading>
  <Text mb={4}>
    We address users in a welcoming and personal way, avoiding jargon that could confuse or alienate them. Whether you're a seasoned developer or someone new to no-code, our communication puts you at ease.
  </Text>

  <Heading as="h3" size="lg" mb={2}>2. Empowering & Encouraging</Heading>
  <Text mb={4}>
    Quilta is about enabling great ideas, so our tone encourages creativity. We show confidence in the user’s ability to build and grow, offering support at every step.
  </Text>

  <Heading as="h3" size="lg" mb={2}>3. Clear & Direct</Heading>
  <Text mb={4}>
    Simplicity is key. We value clarity over complexity, ensuring that every piece of communication, from in-app text to help guides, is easy to understand and actionable.
  </Text>

  <Heading as="h3" size="lg" mb={2}>4. Innovative & Forward-Thinking</Heading>
  <Text mb={4}>
    Quilta is forward-thinking, and this reflects in our language. We talk about the future of app development and how we’re making it more accessible. We aim to inspire users by sharing possibilities and exciting ideas.
  </Text>
</Box>



        {/* Section 5: Color Palette */}
        <Box id="colors" mb={8}>
          <Heading as="h2" size="xl" mb={4}>
            Our Vibrant Color Palette
          </Heading>
          <Text mb={4}>
            Quilta’s color palette reflects the energy, creativity, and innovation that drives everything we do. While our foundation is grounded in clean black and white, we infuse vibrant colors strategically to capture attention, inspire action, and evoke emotion.
          </Text>

          {/* Color Swatches with Descriptions */}
          <SimpleGrid columns={1} spacing={8}>
            {colors.map((color) => (
              <ColorSwatch key={color.hex} color={color.color} hex={color.hex} description={color.description} />
            ))}
          </SimpleGrid>
        </Box>

        {/* Section 6: Gradient Backgrounds */}
        <Box id="gradients" mb={8}>
          <Heading as="h2" size="xl" mb={4}>
            Gradient Backgrounds
          </Heading>
          <Text mb={4}>
            Quilta's branding incorporates clean gradients to create smooth transitions between colors. Gradients should
            be subtle and serve to guide the user’s eye across the interface without overpowering the content. Depending
            on the theme, gradients should adjust to match the light or dark background.
          </Text>
          <Box
            bgGradient={colorMode === 'light' ? 'linear(to-r, #25787b, #212529)' : 'linear(to-r, #25787b, #3d0151)'}
            w="100%"
            h="150px"
            borderRadius="md"
            mb={4}
          ></Box>
          <Text>
            Example of gradients that switch based on theme: Teal Blue to Blossom Pink (Light) and Teal Blue to Dark Grey
            (Dark).
          </Text>
        </Box>

        {/* Section 7: Buttons & Links */}
        <Box id="buttons" mb={8}>
          <Heading as="h2" size="xl" mb={4}>
            Buttons & Links
          </Heading>
          <Text mb={4}>
            Our buttons use vibrant colors from the Attention Palette to encourage action.
          </Text>
          <Button bg="#ffa502" color="white" mb={4}  mr={3}>
            Primary Action - Sunrise Yellow
          </Button>
          <Button bg="#f96048" color="white" mb={4} mr={3}>
            Secondary Action - Lava Red
          </Button>
          <Button bg="#25787b" color="white" mb={4} mr={3}>
            Teal Action - Teal Blue
          </Button>
          <Text mb={4}>
            Links are simple and clean, with hover states activating the Attention Palette colors.
          </Text>
        </Box>

        {/* Section 8: Tables & Components */}
        <Box id="tables" mb={8}>
          <Heading as="h2" size="xl" mb={4}>
            Tables & Components
          </Heading>
          <Text mb={4}>
            Our tables follow a clean and structured design, with bold headers and alternating row colors for improved readability.
          </Text>
          <Table variant="simple" size="md" mb={4}>
            <Thead>
              <Tr>
                <Th>Value</Th>
                <Th>Description</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Innovation</Td>
                <Td>Constantly improving our platform with cutting-edge features</Td>
              </Tr>
              <Tr bg="gray.100">
                <Td>Scalability</Td>
                <Td>Supporting businesses as they grow and expand</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>

        {/* Section 9: Animation and Interaction Guidelines */}
<Box id="animation" mb={8}>
  <Heading as="h3" size="lg" mb={4}>
    Animation and Interaction Guidelines
  </Heading>
  <Text mb={4}>
    Animations in Quilta should enhance the user experience without overwhelming it. Smooth, subtle transitions are key to maintaining simplicity while conveying innovation.
  </Text>

  <Text mb={4}>
    <strong>Duration:</strong> Animations should be between 150ms and 300ms for a balanced transition. For example, when a button is clicked, its background color should transition over 200ms, providing feedback without feeling too fast or slow.
  </Text>

  <Text mb={4}>
    <strong>Easing:</strong> Use ease-in-out for most interactions. For example, when hovering over a card component, the card might slightly lift with a smooth ease-in-out transition, making the interaction feel more fluid and intentional.
  </Text>

  <Text mb={4}>
    <strong>Hover Effects:</strong> Buttons should have soft hover effects like background color changes or slight scaling. For instance, hovering over a button can change its background color and increase its scale by 1.05x over 250ms, providing a visual cue without overwhelming the design.
  </Text>

  {/* Example Button with Hover Animation */}
  <Button 
    bg="#25787b" 
    color="white" 
    mb={4} 
    _hover={{ 
      bg: "#30a234", 
      transform: "scale(1.05)", 
      transition: "background-color 200ms ease-in-out, transform 250ms ease-in-out" 
    }}
  >
    Hover Me!
  </Button>

  {/* Example of card hover effect */}
  <Box 
    bg="gray.100" 
    p={6} 
    borderRadius="md" 
    _hover={{ 
      transform: "scale(1.05)", 
      boxShadow: "xl", 
      transition: "transform 300ms ease-in-out" 
    }}
    mb={6}
  >
    <Text>This card scales on hover and adds a shadow for a subtle emphasis.</Text>
  </Box>
</Box>


{/* Section 10: Illustration and Graphic Style */}
<Box id="illustration" mb={8}>
  <Heading as="h2" size="xl" mb={4}>
    Illustration and Graphic Style
  </Heading>
  <Text mb={4}>
    Quilta’s illustrations should be flat and minimalistic, focusing on human-centered themes. Colors should draw from the attention palette and keep a consistent, friendly tone.
  </Text>

      {/* Responsive Images with Wrap */}

      <Box  position="relative" overflow="hidden"  >
        


        <SimpleGrid columns={{ base: 1, md: 1, lg: 3 }} spacing={12} position="relative" mb={10}>
          <VStack spacing={3} textAlign="center" position="relative">
            <Box position="relative" width="full" height={{ base: "400px", md: "425px" }} display="flex" justifyContent="center" alignItems="center" minH="400px">
              <Image src="/img/blob-sunset.svg" alt="Blob sunset" position="absolute" top="0" left="50%" transform="translateX(-50%)" width={{ base: "400px", md: "450px" }} height={{ base: "400px", md: "450px" }} minWidth="400px" minHeight="400px" zIndex="-1" />
              <Image src="/img/ai-technology.svg" alt="AI technology powering app development" width={{ base: "150px", md: "180px" }} height={{ base: "150px", md: "180px" }} objectFit="contain" zIndex="1" />
            </Box>
          </VStack>

          <VStack spacing={3} textAlign="center" position="relative">
            <Box position="relative" width="full" height={{ base: "400px", md: "425px" }} display="flex" justifyContent="center" alignItems="center" minH="400px">
              <Image src="/img/blob-lava.svg" alt="Blob lava" position="absolute" top="0" left="50%" transform="translateX(-50%)" width={{ base: "400px", md: "450px" }} height={{ base: "400px", md: "450px" }} minWidth="400px" minHeight="400px" zIndex="-1" />
              <Image src="/img/woman-painting.svg" alt="A woman painting to symbolize app customization" width={{ base: "150px", md: "180px" }} height={{ base: "150px", md: "180px" }} objectFit="contain" zIndex="1" />
            </Box>
          </VStack>

          <VStack spacing={3} textAlign="center" position="relative">
            <Box position="relative" width="full" height={{ base: "400px", md: "425px" }} display="flex" justifyContent="center" alignItems="center" minH="400px">
              <Image src="/img/blob-forest.svg" alt="Blob forest" position="absolute" top="0" left="50%" transform="translateX(-50%)" width={{ base: "400px", md: "450px" }} height={{ base: "400px", md: "450px" }} minWidth="400px" minHeight="400px" zIndex="-1" />
              <Image src="/img/man-publishing-site.svg" alt="A man publishing a website to illustrate app deployment" width={{ base: "150px", md: "180px" }} height={{ base: "150px", md: "180px" }} objectFit="contain" zIndex="1" />
            </Box>
          </VStack>

      
        </SimpleGrid>
      </Box>
      </Box>

{/* Section 9: Iconography and Interactive Elements */}
<Box id="iconography" mb={8}>
  <Heading as="h2" size="xl" mb={4}>
    Iconography and Interactive Elements
  </Heading>
  <Text mb={4}>
    Icons play a key role in communicating the interactive nature of Quilta’s platform. They should be bold, simple, and visually aligned with our color palette. Use icons to enhance clarity in navigation, highlight key actions, or represent complex ideas in a visually simple way.
  </Text>
  
  <Text mb={4}>
    Here are some examples of how we use icons in Quilta, varying in size and color to emphasize different actions, messages, or states.
  </Text>
  
  {/* Icon Examples */}
  <HStack spacing={6} mb={8}>

    {/* Basic Icons with Different Colors */}
    <Icon as={MdSettings} boxSize={6} color="black" />
    <Icon as={MdReceipt} boxSize={8} color="black" />
    <Icon as={MdGroupWork} boxSize={10} color="#ffa502" /> {/* Sunrise Yellow */}
    <Icon as={MdPieChart} boxSize={8} color="#f96048" />   {/* Lava Red */}
    <Icon as={MdPhoneIphone} boxSize={8} color="#30a234" /> {/* Forest Green */}
  </HStack>

  <Text mb={4}>
    These icons are used to represent navigation items, key features, and action points throughout the platform. Larger icons or different colors can be used to draw attention to key elements or calls to action.
  </Text>

  {/* Using Icons with Background Colors */}
  <HStack spacing={6} mb={8}>
    <Box bg="#ffa502" p={4} borderRadius="md"> {/* Background: Sunrise Yellow */}
      <Icon as={MdSettings} boxSize={10} color="white" />
    </Box>
    <Box bg="#f96048" p={4} borderRadius="md"> {/* Background: Lava Red */}
      <Icon as={MdReceipt} boxSize={10} color="white" />
    </Box>
    <Box bg="#30a234" p={4} borderRadius="md"> {/* Background: Forest Green */}
      <Icon as={MdGroupWork} boxSize={10} color="white" />
    </Box>
  </HStack>

  <Text mb={4}>
    Background colors can be used to make icons more prominent in buttons, cards, or highlighted actions within the app. The bold, contrasting colors from our attention palette work particularly well for this.
  </Text>

  {/* Custom Icon Example */}
  <Text mb={4}>
    You can also create custom icons for specific elements unique to the Quilta brand. Below is an example of a custom icon created using Chakra's <code>createIcon</code> function:
  </Text>
  <Box mb={8}>
    <Icon viewBox="0 0 200 200" color="#25787b" boxSize={12}>  {/* Teal Blue */}
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </Icon>
    <Text mt={2}>This is a custom icon that could represent a unique Quilta feature or interactive element.</Text>
  </Box>

  {/* Example of Icon Hover Effects */}
  <Text mb={4}>
    Hover effects should be subtle but effective in providing feedback for interactive elements. Below is an example of how icons can change color or size on hover to indicate interaction:
  </Text>
  <HStack spacing={6}>
    <Button variant="ghost" _hover={{ bg: "#e460a3", color: "white" }}> {/* Blossom Pink on Hover */}
      <Icon as={MdCode} boxSize={8} />
      <Text ml={2}>Hover me</Text>
    </Button>
    <Button variant="ghost" _hover={{ bg: "#30a234", color: "white" }}> {/* Forest Green on Hover */}
      <Icon as={MdPhoneIphone} boxSize={8} />
      <Text ml={2}>Hover me</Text>
    </Button>
  </HStack>
</Box>





        {/* Section 11: Web & Digital Guidelines */}
        <Box id="web-guidelines" mb={8}>
          <Heading as="h2" size="xl" mb={4}>
            Web & Digital Guidelines
          </Heading>
          <Text mb={4}>
            Quilta’s digital experience should reflect innovation and accessibility. Design must be responsive, and colors should maintain a contrast ratio for accessibility.
          </Text>
          <Text mb={4}>
            <strong>Breakpoints:</strong> Use breakpoints for mobile, tablet, and desktop designs. 
            <br/>
            <strong>Mobile-First Design:</strong> Prioritize mobile usability with easily tappable elements.
            <br/>
            <strong>Web-Safe Colors:</strong> Ensure all colors are web-safe for consistent appearance across all devices.
          </Text>
        </Box>

        {/* Section 12: Spacing and Layout Guidelines */}
        <Box id="spacing" mb={8}>
          <Heading as="h2" size="xl" mb={4}>
            Spacing and Layout Guidelines
          </Heading>
          <Text mb={4}>
            A consistent grid system ensures that layouts are well-balanced and aesthetically pleasing. 
          </Text>
          <Text mb={4}>
            Use a 12-column grid with gutters of 16px for mobile, 24px for tablets, and 32px for desktop. Maintain a margin of 24px around page edges to prevent crowding, and use 16px to 24px of padding inside containers and buttons.
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default BrandBookPage;

