import React from 'react';
import styled from 'styled-components';

const SidebarContainer = styled.div`
  position: fixed; /* Or absolute if you prefer */
  top: 0;
  right: 0;
  width: 250px; /* Adjust width as needed */
  height: 100vh;
  background-color: #f4f4f4;
  border-left: 1px solid #ddd;
  z-index: 1000; /* Ensure it's on top */
  padding: 1rem;
  overflow-y: auto; /* Allows scrolling if content exceeds viewport */
`;

const ComponentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ComponentItem = styled.div`
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  &:hover {
    background-color: #eaeaea;
  }
`;

const Sidebar = ({ components, onDragStart }) => {
  return (
    <SidebarContainer>
      <ComponentList>
        {components.map((component) => (
          <ComponentItem
            key={component.id}
            draggable
            onDragStart={(e) => onDragStart(e, component)}
          >
            {component.name}
          </ComponentItem>
        ))}
      </ComponentList>
    </SidebarContainer>
  );
};

export default Sidebar;