import React from 'react';
import styled from 'styled-components';
import Navbar from './Navbar'; // Import the Navbar component

// Define the HeaderContainer styled component
const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
`;

// Define a BannerSection that will allow future banners
const BannerSection = styled.div`
  height: 50px;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = () => (
  <HeaderContainer>
    <Navbar />
    <BannerSection>
      {/* Future banners can be added here */}
      Banner Section
    </BannerSection>
  </HeaderContainer>
);

export default Header;