import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Image,
  Button,
  Icon,
  useColorMode,
  SimpleGrid,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react';
import { MdSettings, MdBuild, MdSecurity, MdSpeed, MdCloud, MdCode, MdPublish, MdMoney } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/quiltaComponents/Footer';

const HomePage = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [scrollY, setScrollY] = useState(0);
  const [logoSize, setLogoSize] = useState(600);
  const [showDarkNav, setShowDarkNav] = useState(false);
  const logoPosition = "50%";
  const isBase = useBreakpointValue({ base: true, md: false }); // Check if screen is base
  const isMd = useBreakpointValue({ base: false, md: true, lg: false });
  const navBg = useColorModeValue(showDarkNav ? '#25787b' : 'transparent', showDarkNav ? 'gray.800' : 'transparent');

  useEffect(() => {
    const scrollThresholdNav = window.innerHeight * 0.3; // 50% for dark nav
    const scrollThresholdLogo = window.innerHeight * 0.28; // 40% for shrinking logo

    const handleScroll = () => {
      setScrollY(window.scrollY);

      if (window.scrollY > scrollThresholdNav) {
        setShowDarkNav(true);
      } else {
        setShowDarkNav(false);
      }

      if (window.scrollY > scrollThresholdLogo) {
        setLogoSize(80);
      } else {
        setLogoSize(600);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <Box>
      {/* Sticky Navbar */}
      <Box
        as="nav"
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex="1000"
        bg={navBg}
        boxShadow={showDarkNav ? 'lg' : 'none'}
        transition="background-color 0.3s, box-shadow 0.3s"
        py={3}
        color="white"
      >
        <HStack justifyContent="space-between" px={8} alignItems="center">
          <Button onClick={() => navigate('/signup')} as="a" href="#community" variant="ghost" color="white">
          {isBase ? null : 'Join Our Community'}
          </Button>

          {/* Nav Logo - Centered when showDarkNav is true */}
          {showDarkNav && (
            <Box position="absolute" left="50%" transform="translateX(-50%)">
              <Image
                src="/logo/quilta-logo-dark.png"
                alt="Quilta Logo"
                width="120px"
                transition="all 0.9s ease"
                maxWidth="600px"
              />
            </Box>
          )}

          <HStack spacing={6} ml="auto" color="white">
          {!isBase && (
              <>

{!isMd && (
              <Button onClick={() => navigate('/signup')} as="a" href="#features" variant="ghost" color="white">
                Features
              </Button>
            )}
            
            <Button onClick={() => navigate('/signup')} as="a" href="#cta" variant="ghost" color="white">
              Sign Up Now
            </Button>
            </>
            )}
            <Button onClick={() => navigate('/signup')} as="a" href="#login" variant="outline" colorScheme="#ffa502">
              Login
            </Button>
          </HStack>
        </HStack>
      </Box>

      {/* Hero Section with CTA and Large Logo */}
      <Box
  id="landing"
  w="100%"
  h="100vh"
  bgGradient={colorMode === 'light' ? 'linear(to-r, #212529, #25787b)' : 'linear(to-r, #25787b, #3d0151)'}
  display="flex"
  justifyContent="center"
  alignItems="center"
  textAlign="center"
  color="white"
  px={4}
>
  <VStack spacing={6} align="center">
    <Image
      src="/logo/quilta-logo-dark.png"
      alt="Quilta Logo"
      width="35vw" // Adjust the width as needed
      maxWidth="80%"
      transition="all 0.3s ease"
    />
    <Text fontSize="2xl">
      Create apps in minutes with AI-powered tools. Publish instantly. No coding required.
    </Text>
    <Button onClick={() => navigate('/signup')} size="lg" bg="#ffa502" color="black" _hover={{ bg: 'yellow.300' }}>
      Sign Up Now
    </Button>
  </VStack>
</Box>


      {/* Description Section */}
      <Box id="description" py={75} px={75}  bg={colorMode === 'light' ? 'gray.50' : 'gray.800'} >
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={20} alignItems="center" mb={10} mt={10}>
          <Image 
            src="/img/Quilta-mock-app.png" 
            alt="Mockup of Quilta app interface" 
            style={{ borderRadius: '5px' }} 
            maxHeight="600px" 
            objectFit="cover" 
            mx="auto"
          />
          <VStack align="left" spacing={4} py={10} px={10}>
            <Heading as="h2" size="xl" fontWeight="bold">
              Quilta: Where Ideas Become Apps
            </Heading>
            <Text fontSize="lg">
              Quilta is your no-code solution to build, publish, and manage applications with ease. Whether you’re looking
              to create apps for productivity, e-commerce, or communication, Quilta empowers everyone with AI-driven tools
              to build apps that are powerful, flexible, and secure.
            </Text>
            <Button onClick={() => navigate('/signup')} size="md" variant="outline" colorScheme="teal">
              Learn More
            </Button>
          </VStack>
        </SimpleGrid>
      </Box>

      {/* Timeline Section */}
      <Box id="timeline" py={75} px={75}  position="relative" overflow="hidden" mb={10} mt={10}>
        <Heading as="h2" size="md" mb={2} color={'#81e6d9'}>
          The Quilta Story
        </Heading>
        <Heading as="h2" size="xl" mb={5}>
          Your App's Journey Unfolds...
        </Heading>

        <Box
          position="absolute"
          top="250px"
          left="0"
          right="0"
          zIndex="-2"
          bgImage={useColorModeValue('/img/dashed-wavy-line-light.svg', '/img/dashed-wavy-line-dark.svg')}
          bgRepeat="no-repeat"
          bgPosition="center"
          bgSize="cover"
          minHeight="340px"
        />

        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={12} position="relative" mb={10}>
          <VStack spacing={3} textAlign="center" position="relative">
            <Box position="relative" width="full" height={{ base: "400px", md: "425px" }} display="flex" justifyContent="center" alignItems="center" minH="400px">
              <Image src="/img/blob-sunset.svg" alt="Blob sunset" position="absolute" top="0" left="50%" transform="translateX(-50%)" width={{ base: "400px", md: "450px" }} height={{ base: "400px", md: "450px" }} minWidth="400px" minHeight="400px" zIndex="-1" />
              <Image src="/img/ai-technology.svg" alt="AI technology powering app development" width={{ base: "150px", md: "180px" }} height={{ base: "150px", md: "180px" }} objectFit="contain" zIndex="1" />
            </Box>
            <Text fontSize="2xl" fontWeight="bold" color="#ffa502">Build with AI</Text>
            <Text fontSize="xl">Let Quilta's AI build the foundation of your app based on your needs in just minutes.</Text>
          </VStack>

          <VStack spacing={3} textAlign="center" position="relative">
            <Box position="relative" width="full" height={{ base: "400px", md: "425px" }} display="flex" justifyContent="center" alignItems="center" minH="400px">
              <Image src="/img/blob-lava.svg" alt="Blob lava" position="absolute" top="0" left="50%" transform="translateX(-50%)" width={{ base: "400px", md: "450px" }} height={{ base: "400px", md: "450px" }} minWidth="400px" minHeight="400px" zIndex="-1" />
              <Image src="/img/woman-painting.svg" alt="A woman painting to symbolize app customization" width={{ base: "150px", md: "180px" }} height={{ base: "150px", md: "180px" }} objectFit="contain" zIndex="1" />
            </Box>
            <Text fontSize="2xl" fontWeight="bold" color="#f96048">Tweak & Style</Text>
            <Text fontSize="xl">Customize your app’s design, content, and functionality to perfectly match your brand.</Text>
          </VStack>

          <VStack spacing={3} textAlign="center" position="relative">
            <Box position="relative" width="full" height={{ base: "400px", md: "425px" }} display="flex" justifyContent="center" alignItems="center" minH="400px">
              <Image src="/img/blob-forest.svg" alt="Blob forest" position="absolute" top="0" left="50%" transform="translateX(-50%)" width={{ base: "400px", md: "450px" }} height={{ base: "400px", md: "450px" }} minWidth="400px" minHeight="400px" zIndex="-1" />
              <Image src="/img/man-publishing-site.svg" alt="A man publishing a website to illustrate app deployment" width={{ base: "150px", md: "180px" }} height={{ base: "150px", md: "180px" }} objectFit="contain" zIndex="1" />
            </Box>
            <Text fontSize="2xl" fontWeight="bold" color="#30a234">Publish</Text>
            <Text fontSize="xl">Launch your app with a single click. Quilta handles the deployment and hosting for you.</Text>
          </VStack>

          <VStack spacing={3} textAlign="center" position="relative">
            <Box position="relative" width="full" height={{ base: "400px", md: "425px" }} display="flex" justifyContent="center" alignItems="center" minH="400px">
              <Image src="/img/blob-blossom.svg" alt="Blob blossom" position="absolute" top="0" left="50%" transform="translateX(-50%)" width={{ base: "400px", md: "450px" }} height={{ base: "400px", md: "450px" }} minWidth="400px" minHeight="400px" zIndex="-1" />
              <Image src="/img/winning-with-quilta.svg" alt="Symbol of success and growth using Quilta" width={{ base: "150px", md: "180px" }} height={{ base: "150px", md: "180px" }} objectFit="contain" zIndex="1" />
            </Box>
            <Text fontSize="2xl" fontWeight="bold" color="#e460a3">Reap the Rewards</Text>
            <Text fontSize="xl">Increase your revenue or productivity by automating tasks and optimizing your workflows.</Text>
          </VStack>
        </SimpleGrid>
      </Box>

      <Box id="story-to-features-hero" position="relative" w="100%" h="700px" overflow="hidden">
 

  {/* Image */}
  <Image
    src="/img/quilta-users-happy-outdoor.jpg"
    alt="Happy Quilta users outdoors"
    objectFit="cover"
    w="100%"
    h="100%"
    filter="brightness(0.7)"
    loading="lazy"
    srcSet="/img/quilta-users-happy-outdoor-small.jpg 600w, /img/quilta-users-happy-outdoor-medium.jpg 1200w, /img/quilta-users-happy-outdoor-large.jpg 1800w"
    sizes="(max-width: 600px) 600px, (max-width: 1200px) 1200px, 1800px"
  />

  <Text 
    position="absolute"
    top="10%"
    left="10%"
    fontSize={{ base: "2xl", md: "6xl" }}
    fontWeight="extrabold"
    color="white"
    zIndex="2"
    lineHeight="shorter"
    maxW="60%"
  >
    Building innovation anywhere, empowering every step of the way.
  </Text>

</Box>


      
{/* Features Section */}
<Box id="features" py={75} px={75} bg={colorMode === 'light' ? 'gray.50' : 'gray.800'}>
  <Heading as="h2" size="2xl" textAlign="center" mb={10} mt={10}>
    Quilta’s Features
  </Heading>

  <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10} mb={20} mt={25}>
    {/* Feature 1: Hosting */}
    <Box
      border="1px solid"
      borderRadius="1rem"
      p={6}
      transition="box-shadow 0.3s ease-in-out"
      borderColor="#4FD1C5"
      bgGradient={colorMode === 'light' ? 'linear(to-r, #25787b, #4FD1C5)' : 'linear(to-r, #212529, #25787b)'}
    >
      <Heading color="#fff" as="h3" size="lg" fontWeight="bold">
        Seamless Hosting
      </Heading>
      <Text color="#fff" fontSize="md" mt={2}>
        We handle your app's hosting with cutting-edge technology.
      </Text>
      <Box as="ul" pl={4} mt={4}>
        <Text as="li" color="#fff" fontSize="md">Fast CDN-backed hosting</Text>
        <Text as="li" color="#fff" fontSize="md">Automatic backups</Text>
        <Text as="li" color="#fff" fontSize="md">99.99% uptime guarantee</Text>
      </Box>
      <Icon as={MdCloud} boxSize={24} color="#fff" mt={4} />
    </Box>

    {/* Feature 2: Automation */}
    <Box
      border="1px solid"
      borderRadius="1rem"
      p={6}
      transition="box-shadow 0.3s ease-in-out"
      borderColor="#4FD1C5"
      bgGradient={colorMode === 'light' ? 'linear(to-r, #25787b, #6a1b9a)' : 'linear(to-r, #25787b, #3d0151)'}
    >
      <Heading color="#fff" as="h3" size="lg" fontWeight="bold">
        Automation at Its Best
      </Heading>
      <Text color="#fff" fontSize="md" mt={2}>
        Streamline operations with powerful automation workflows.
      </Text>
      <Box as="ul" pl={4} mt={4}>
        <Text as="li" color="#fff" fontSize="md">Workflow automation</Text>
        <Text as="li" color="#fff" fontSize="md">Task scheduling</Text>
        <Text as="li" color="#fff" fontSize="md">Custom triggers and actions</Text>
      </Box>
      <Icon as={MdSpeed} boxSize={24} color="#fff" mt={4} />
    </Box>

    {/* Feature 3: Security */}
    <Box
      border="1px solid"
      borderRadius="1rem"
      p={6}
      transition="box-shadow 0.3s ease-in-out"
      borderColor="#4FD1C5"
      bgGradient={colorMode === 'light' ? 'linear(to-r, #3d0151, #6a1b9a)' : 'linear(to-r, #6a1b9a, #3d0151)'}
    >
      <Heading color="#fff" as="h3" size="lg" fontWeight="bold">
        Top-notch Security
      </Heading>
      <Text color="#fff" fontSize="md" mt={2}>
        Keep your app and its users safe with industry-leading security.
      </Text>
      <Box as="ul" pl={4} mt={4}>
        <Text as="li" color="#fff" fontSize="md">End-to-end encryption</Text>
        <Text as="li" color="#fff" fontSize="md">Multi-factor authentication</Text>
        <Text as="li" color="#fff" fontSize="md">DDoS protection</Text>
      </Box>
      <Icon as={MdSecurity} boxSize={24} color="#fff" mt={4} />
    </Box>

    {/* Feature 4: Simplicity & Flexibility */}
    <Box
      border="1px solid"
      borderRadius="1rem"
      p={6}
      transition="box-shadow 0.3s ease-in-out"
      borderColor="#4FD1C5"
      bgGradient={colorMode === 'light' ? 'linear(to-r, #6a1b9a, #4FD1C5)' : 'linear(to-r, #3d0151, #6a1b9a)'}
    >
      <Heading color="#fff" as="h3" size="lg" fontWeight="bold">
        Simplicity and Flexibility
      </Heading>
      <Text color="#fff" fontSize="md" mt={2}>
        Get started with no-code, and customize as needed.
      </Text>
      <Box as="ul" pl={4} mt={4}>
        <Text as="li" color="#fff" fontSize="md">Drag-and-drop builder</Text>
        <Text as="li" color="#fff" fontSize="md">No-code customization</Text>
        <Text as="li" color="#fff" fontSize="md">Advanced developer options</Text>
      </Box>
      <Icon as={MdSettings} boxSize={24} color="#fff" mt={4} />
    </Box>
  </SimpleGrid>
</Box>



      {/* CTA Section */}
      <Box id="cta" py={10} pt={20} px={5} bgGradient="linear(to-r, blue.600, teal.500)" color="white" textAlign="center">
        <Heading as="h2" size="2xl" mb={4}>
          Start Building with Quilta Today
        </Heading>
        <Text fontSize="lg" mb={8}>
          Whether you’re looking to boost productivity or launch your next big idea, Quilta is your platform for success.
        </Text>
        <Button onClick={() => navigate('/signup')} size="lg" bg="#ffa502" color="black" _hover={{ bg: 'yellow.300' }}>
          Sign Up Now
        </Button>
        <Box mt={20}>
        <Footer />
        </Box>
      </Box>
      
    </Box>
   

  );
};

export default HomePage;
