import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import App from './App'; 

const httpLink = createHttpLink({
  uri: 'http://127.0.0.1:8000/graphql/', 
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('authToken');
  const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]')?.value;  

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      'X-CSRFToken': csrfToken,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ApolloProvider client={client}>
     <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);
