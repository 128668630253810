import { gql } from '@apollo/client';

export const GET_APP_PAGE_CONTENT_OBJECTS = gql`
  query GetAppPageContentObjects($appId: ID!, $pageId: ID!) {
    appPage(appId: $appId, pageId: $pageId) {
      id
      name
      contentObjects {
        id
        component {
          id
          name
          component_type
          props
        }
        x
        y
        width
        height
        linkedContent {  # Use camelCase here
          fieldName    # Use fieldName (camelCase)
          value
          fieldType    # Use fieldType (camelCase)
        }
      }
    }
  }
`;

export const GET_COMPONENTS = gql`
  query GetComponents {
  components {
    id
    name
    componentType  # This should now work
    props
  }
}
`;