// GridEditor.js

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Rnd } from 'react-rnd';
import { useMutation } from '@apollo/client';
import { CREATE_APP_CONTENT_OBJECT, UPDATE_APP_CONTENT_OBJECT, DELETE_APP_CONTENT_OBJECT } from '../../utils/graphql/mutations';
import RenderComponent from './RenderComponent';
import { getDefaultContentValue } from '../../utils/component/defaultValue';
import { EditIcon, CloseIcon } from '@chakra-ui/icons';

const GridEditorContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(20, 1fr);
  grid-gap: 0;
  min-height: 80vh;
  border: 1px solid #ddd;
  background-color: #f0f0f0;
  position: relative;
  padding: 0;
  background-image: radial-gradient(circle, #bbb 1px, transparent 1px);
  background-size: 50px 50px;
  background-position: -25px -25px;
`;

const GridItemWrapper = styled.div`
  position: relative;
`;

const GridItem = styled(Rnd)`
  background-color: #61dafb;
  text-align: center;
  color: #fff;
  cursor: move;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  gap: 5px;
`;

const IconButtonStyled = styled.button`
  background: transparent;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 24px;
  height: 24px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const snapToGrid = (value, gridSize) => Math.round(value / gridSize) * gridSize;

const GridEditor = ({ initialItems, pageId = "1" }) => {
  const [items, setItems] = useState([]);
  const gridSize = 50;

  const [createAppContentObject] = useMutation(CREATE_APP_CONTENT_OBJECT, {
    onError: (error) => console.error('Creation error:', error),
  });

  const [updateAppContentObject] = useMutation(UPDATE_APP_CONTENT_OBJECT, {
    onError: (error) => console.error('Update error:', error),
  });

  const [deleteAppContentObject] = useMutation(DELETE_APP_CONTENT_OBJECT, {
    onError: (error) => console.error('Deletion error:', error),
  });

  useEffect(() => {
    if (initialItems && initialItems.length > 0) {
      const formattedItems = initialItems.map(item => {
        const component = item?.component || {};
        return {
          id: item.id,
          x: item.x,
          y: item.y,
          width: item.width,
          height: item.height,
          componentId: component.id || item.componentId,
          componentType: component.component_type || item.componentType,
          linkedContent: item.linkedContent || [],
        };
      });
      setItems(formattedItems);
    }
  }, [initialItems]);

  const handleDelete = async (itemId) => {
    const { data } = await deleteAppContentObject({
      variables: { id: itemId },
    });

    if (data && data.deleteAppContentObject) {
      setItems(items.filter((item) => item.id !== itemId));
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const componentId = e.dataTransfer.getData('componentId');
    const componentType = e.dataTransfer.getData('componentType');  // This needs to be correct
    
    const x = snapToGrid(e.clientX - e.target.getBoundingClientRect().left, gridSize);
    const y = snapToGrid(e.clientY - e.target.getBoundingClientRect().top, gridSize);
    
    // Get default content based on component type
    const defaultLinkedContent = getDefaultContentValue(componentType);  // This needs to work based on componentType
  
    // Create the app content object in the backend
    const { data } = await createAppContentObject({
      variables: {
        pageId,
        componentId,
        x,
        y,
        width: gridSize,
        height: gridSize,
        linkedContent: defaultLinkedContent,
      },
    });
    
    if (data && data.createAppContentObject) {
      const newItem = {
        id: data.createAppContentObject.id,
        componentId: data.createAppContentObject.component.id,
        componentType: data.createAppContentObject.component.component_type,
        x: data.createAppContentObject.x,
        y: data.createAppContentObject.y,
        width: data.createAppContentObject.width,
        height: data.createAppContentObject.height,
        linkedContent: data.createAppContentObject.linkedContent || [],
      };
      setItems([...items, newItem]);
    }
  };

  const handleDragStop = async (e, data, item) => {
    const updatedItem = {
      ...item,
      x: snapToGrid(data.x, gridSize),
      y: snapToGrid(data.y, gridSize),
    };
    setItems(items.map((i) => (i.id === item.id ? updatedItem : i)));
    await saveItem(updatedItem);
  };

  const handleResizeStop = async (e, direction, ref, delta, position, item) => {
    const updatedItem = {
      ...item,
      width: snapToGrid(ref.offsetWidth, gridSize),
      height: snapToGrid(ref.offsetHeight, gridSize),
      x: snapToGrid(position.x, gridSize),
      y: snapToGrid(position.y, gridSize),
    };
    setItems(items.map((i) => (i.id === item.id ? updatedItem : i)));
    await saveItem(updatedItem);
  };

  const saveItem = async (item) => {
    try {
      const { data } = await updateAppContentObject({
        variables: {
          id: item.id,
          x: item.x,
          y: item.y,
          width: item.width,
          height: item.height,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateAppContentObject: {
            __typename: 'AppContentObject',
            id: item.id,
            x: item.x,
            y: item.y,
            width: item.width,
            height: item.height,
          },
        },
      });
      if (data) {
        console.log('Item saved:', data.updateAppContentObject);
      }
    } catch (error) {
      console.error('Error saving item:', error);
    }
  };

  return (
    <GridEditorContainer onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
      {items.map((item) => (
        <GridItem
          key={item.id}
          default={{
            x: item.x,
            y: item.y,
            width: item.width,
            height: item.height,
          }}
          minWidth={gridSize}
          minHeight={gridSize}
          bounds="parent"
          dragGrid={[gridSize, gridSize]}
          resizeGrid={[gridSize, gridSize]}
          onDragStop={(e, data) => handleDragStop(e, data, item)}
          onResizeStop={(e, direction, ref, delta, position) => handleResizeStop(e, direction, ref, delta, position, item)}
        >
          <RenderComponent componentType={item.componentType} linkedContent={item.linkedContent} />
          <IconContainer>
              <IconButtonStyled onClick={() => console.log('Edit clicked for item:', item.id)}>
                <EditIcon />
              </IconButtonStyled>
              <IconButtonStyled onClick={() => handleDelete(item.id)}>
                <CloseIcon />
              </IconButtonStyled>
            </IconContainer>
        </GridItem>
      ))}
    </GridEditorContainer>
  );
};

export default GridEditor;
